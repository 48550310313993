import * as PIXI from 'pixi.js';

export default class Label extends PIXI.Text {

    constructor(style, text) {
        super(text || 'Default Label Text', style);
        this.initialize(style, text);
    }

    initialize(style, text) {
        this.setStyle(style);
    }

    setStyle(style) {
        this.style = style;
    }

    clone() {
        // Return a new instance of Label with the same style and text
        return new Label(this.style, this.text);
    }

    onUpdate(dt) {
        // Implement your onUpdate logic here
    }

    get txt() {
        return this.text;
    }

    set txt(value) {
        this.text = value;
    }
}

Label.TEXT_H_ALIGN_CENTER = 'center';
Label.TEXT_H_ALIGN_LEFT = 'left';
Label.TEXT_H_ALIGN_RIGHT = 'right';

Label.TEXT_V_ALIGN_TOP = 'top';
Label.TEXT_V_ALIGN_MIDDLE = 'middle';
Label.TEXT_V_ALIGN_BOTTOM = 'bottom';
Label.TEXT_V_ALIGN_ALPHABETIC = 'alphabetic';
