const Util = {};

Util.Math = {
    randomFloat : function (min, max) {
        return min + Math.random() * (max - min);
    },

    randomInt : function (min, max) {
        return Math.floor(min + Math.random() * (max - min + 1));
    },

    normalize : function (value, min, max) {
        return (value - min) / (max - min);
    },

    lerp : function (norm, min, max) {
        return (max - min) * norm + min;
    },

    map : function (value, sourceMin, sourceMax, destMin, destMax) {
        return this.lerp( this.normalize(value, sourceMin, sourceMax), destMin, destMax );
    },

    clamp : function (value, min, max) {
        return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
    },

    roundDecimal : function (value, places) {
        var mult = Math.pow(10, places);
        return Math.round(value * mult) / mult;
    },

    getDistance : function (p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    },

    getAngle : function (p1, p2) {
        if (p1 === null || p2 === null) {
            return 0;
        }
        return Math.atan2(p2.y - p1.y, p2.x - p1.x);
    },

    degreesToRadians : function (degrees) {
        return degrees / 180 * Math.PI;
    },

    radiansToDegrees : function (radians) {
        return radians * 180 / Math.PI;
    }
};

Util.Array = {
    removeElement : function( arr, elementToRemove )
    {
        let removeCounter = 0;

        for (let index = 0; index < arr.length; index++) {
            if (arr[index] === elementToRemove) {
                arr.splice(index, 1);
                removeCounter++;
                index--;
            }
        }
        return removeCounter;
    }
};

export { Util };