import * as PIXI from 'pixi.js';
import './utils/extraPIXI.js';
import Wheel from './wheel.js';
import Button from './button.js';
import Knob from './knob.js';
import {Util} from './util.js';
import Slot from './slot.js';
import WinPopup from './winPopup';
import { Assets } from 'pixi.js';
import TweenGeneric from './tweening/tween_generic.js';

export default class MainScene {

    constructor(stage) {
        this.stage = stage;
        this.isLoaded = false;
        this.preload().then(() => {
            this.isLoaded = true;
            this.init();
        });
    }

    async preload() {
        const manifest = {
            bundles: [
                {
                    name: 'load-screen',
                    assets: [
                        {
                            name: 'Montserrat-Bold',
                            srcs: 'assets/fonts/Montserrat-Bold.ttf',
                        },
                        {
                            name: 'Montserrat-Regular',
                            srcs: 'assets/fonts/Montserrat-Regular.ttf',
                        },
                        {
                            name: 'rounded-5',
                            srcs: 'assets/blank/rounded-5.png',
                        },
                        {
                            name: 'rounded-10',
                            srcs: 'assets/blank/rounded-10.png',
                        },
                        {
                            name: 'rounded-15',
                            srcs: 'assets/blank/rounded-15.png',
                        },
                        {
                            name: 'rounded-20',
                            srcs: 'assets/blank/rounded-20.png',
                        },

                        {
                            name: 'aviatrix',
                            srcs: 'assets/icons/aviatrix.png',
                        },
                        {
                            name: 'gonzosquest',
                            srcs: 'assets/icons/gonzosquest.png',
                        },
                        {
                            name: 'jamminjars',
                            srcs: 'assets/icons/jamminjars.png',
                        },
                        {
                            name: 'mental',
                            srcs: 'assets/icons/mental.png',
                        },
                        {
                            name: 'reactoonz',
                            srcs: 'assets/icons/reactoonz.png',
                        },
                        {
                            name: 'wanted',
                            srcs: 'assets/icons/wanted.png',
                        },
                        {
                            name: 'background',
                            srcs: 'assets/background@2x.png',
                        },
                        {
                            name: 'middle_bg',
                            srcs: 'assets/middle_bg@2x.png',
                        },
                        {
                            name: 'large-spinner',
                            srcs: 'assets/large-spinner@2x.png',
                        },
                        {
                            name: 'small-spinner',
                            srcs: 'assets/small-spinner@2x.png',
                        },
                        {
                            name: 'ring_bg',
                            srcs: 'assets/ring_bg.png',
                        },
                        {
                            name: 'outer-ring',
                            srcs: 'assets/outer-ring@2x.png',
                        },
                    ],
                }
            ]
        };

        await Assets.init({manifest});
        return await Assets.loadBundle('load-screen');
    }

    init() {
        this.wheelGroup = new PIXI.Container();
        this.stage.addChild(this.wheelGroup);

        this.windowSize = this.windowSize();

        this.wheelBackground = PIXI.Sprite.from(PIXI.utils.TextureCache['background']);
        this.wheelBackground.anchor.set(0.5, 0.5);
        this.wheelGroup.addChild(this.wheelBackground);

        const bigWheelConf = {
            radius: 320,
            shadowThickness: 20,
            outerWhiteLineThickness: 3,
            linesColor: 0xffffff,
            linesThickness: 4,
            labels: ['50', '75', '100', '150', '250', '10', '25', '40'],
            color: 0x15253f,
            evenColor: 0xa7f2b9,
            winColor: 0xeab054,
            pieScale: 1,
            fontSize: 40,
            textOffsetY: 40,
        };

        const smallWheelConf = {
            radius: 220,
            shadowThickness: 10,
            outerWhiteLineThickness: 3,
            linesColor: 0xffffff,
            linesThickness: 4,
            labels: ['x2', 'x4', 'x6', 'x10', 'X15', 'X20', 'X0.5', 'X1'],
            color: 0xa7f2b9,
            evenColor: 0x15253f,
            winColor: 0xeab054,
            pieScale: 0.69,
            fontSize: 30,
            textOffsetY: 60,
        };

        this.bigWheel = new Wheel(bigWheelConf);
        this.bigWheel.id = 'bigWheel';
        this.bigWheel.delegate = this;
        this.bigWheel.values = [50, 75, 100, 150, 250, 10, 25, 40];
        this.bigWheel.maxSpeed = 0.15;
        this.bigWheel.init();
        this.wheelGroup.addChild(this.bigWheel);

        this.smallWheelBg = PIXI.Sprite.from(PIXI.utils.TextureCache['middle_bg']);
        this.smallWheelBg.anchor.set(0.5, 0.5);
        this.wheelGroup.addChild(this.smallWheelBg);

        this.smallWheel = new Wheel(smallWheelConf);
        this.smallWheel.id = 'smallWheel';
        this.smallWheel.delegate = this;
        this.smallWheel.maxSpeed = 0.15;
        this.smallWheel.values = ['x2', 'x4', 'x6', 'x10', 'x15', 'x20', 'x0.5', 'x1'];
        this.smallWheel.init();
        this.wheelGroup.addChild(this.smallWheel);

        this.smallRingBg = PIXI.Sprite.from(PIXI.utils.TextureCache['ring_bg']);
        this.smallRingBg.anchor.set(0.5, 0.5);
        this.wheelGroup.addChild(this.smallRingBg);
        this.smallRingBg.visible = false;

        this.slot = new Slot();
        this.slot.id = 'slot';
        this.slot.delegate = this;
        this.wheelGroup.addChild(this.slot);
        this.slot.visible = false;

        this.smallRing = PIXI.Sprite.from(PIXI.utils.TextureCache['outer-ring']);
        this.smallRing.anchor.set(0.5, 0.5);
        this.wheelGroup.addChild(this.smallRing);
        this.smallRing.visible = false;


        this.spinButton = new Button('gamescroller', 'gamescroller', this.onSpinnerClicked.bind(this));
        this.spinButton.content.scale.set(1);
        this.wheelGroup.addChild(this.spinButton);

        this.knob = new Knob();
        this.knob.y = -370;
        this.wheelGroup.addChild(this.knob);

        this.winPopup = new WinPopup();
        this.stage.addChild(this.winPopup);

        this.positionElements();
    }

    slotAnimation() {
        this.spinButton.visible = false;
        this.slot.visible = true;
        this.smallRing.visible = true;
        this.smallRingBg.visible = true;

        this.objectAnimation(this.slot);
        this.objectAnimation(this.smallRing);
        this.objectAnimation(this.smallRingBg);
    }

    objectAnimation(object) {

        this.scaleAnimation(object, 1.1, 200, () => {
            this.scaleAnimation(object, 0.9, 200, () => {
                this.scaleAnimation(object, 1.1, 400, () => {
                    this.scaleAnimation(object, 1, 200, () => {

                    });
                });
            });
        });
    }

    scaleAnimation(object, to, duration, callback) {

        new TweenGeneric(object.scale, {x: to}, null, duration).run();
        new TweenGeneric(object.scale, {y: to}, null, duration, callback).run();

    }

    windowSize() {
        var e = window, a = 'inner';
        if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return {width: e[ a + 'Width' ], height: e[ a + 'Height' ]};
    }

    onWheelSegmentPass(currentSpeed, wheel) {
        if (wheel.id === 'bigWheel') {
            this.knob.hit(currentSpeed);
        }
    }

    onSpinnerClicked() {
        if (this.bigWheel.isSpinning) {
            return;
        }

        this.slot.spin();
        this.bigWheel.spin();
        this.smallWheel.spin();

        this.spinButton.visible = false;
        this.slot.visible = true;
        this.smallRing.visible = true;
        this.smallRingBg.visible = true;

        this.stopSpinningAt(4, 3, 1);
    }

    stopSpinningAt(bigWheelIndex, smallWheelIndex, slotIndex) {
        setTimeout(() => {
            this.bigWheel.stopAtIndex(bigWheelIndex);
        }, 2000);

        setTimeout(() => {
            this.smallWheel.stopAtIndex(smallWheelIndex);
        }, 6000);

        setTimeout(() => {
            this.slot.stopAtIndex(slotIndex);
        }, 10000);
    }

    update(delta) {
        if (!this.isLoaded) {
            return;
        }

        this.bigWheel.update(delta);
        this.smallWheel.update(delta);
        this.knob.update(delta);
        this.slot.update(delta);
    }

    positionElements() {
        this.wheelGroup.position.set(this.windowSize.width / 2, this.windowSize.height / 2);
        this.winPopup.position.set(this.windowSize.width / 2, this.windowSize.height / 2);
    }

    onResize() {
        this.positionElements();
    }

    onWin(wheel) {
        if (this.smallWheel.hasWin && this.bigWheel.hasWin && this.slot.hasWin) {
            
            this.slotAnimation();
            setTimeout(() => {
                //
                const multiplicator = parseInt(this.smallWheel.getWin().replace('x', ''));
                const value = parseInt(this.bigWheel.getWin());
                const game = this.slot.getWin();
                this.winPopup.show(multiplicator, value, game);
            }, 1500);
        }
    }

}
