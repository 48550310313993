import * as PIXI from 'pixi.js';
import Label from './label';
import NineSlice from  './nineSlice';
import {convertColor} from '../utils/helpers';

export default class Button extends PIXI.Sprite {

    constructor(text, options) {
        options = options || {};
        const style = options.style; // || Default.styles.Label;

        super();
        this.initialize(text, options);
    }

    initialize(text, options) {

        options.properties = options.properties || {};
        const properties = options.properties;

        properties.backgroundColorNormal = properties.backgroundColorNormal || '#8AC6D0';
        properties.backgroundColorDown = properties.backgroundColorDown || '#50A8B9';
        properties.backgroundColorHover = properties.backgroundColorHover || '#C5E2E8';
        properties.backgroundColorDisabled = properties.backgroundColorDisabled || '#bababa';
        properties.textColorNormal = properties.textColorNormal || '#36213E';
        properties.textColorDown = properties.textColorDown || '#36213E';
        properties.textColorHover = properties.textColorHover || '#36213E';
        properties.textColorDisabled = properties.textColorDisabled || '#cccccc';
        properties.imageNormal = properties.imageNormal || 'white';
        properties.imageSelected = properties.imageSelected || 'white';
        properties.padding = properties.padding || '1';
        properties.width = properties.width || 200;
        properties.height = properties.height || 80;
        properties.isNineSlice = (properties.isNineSlice !== undefined) ? properties.isNineSlice : true;
        properties.sensorWidth = properties.sensorWidth || 0;
        properties.sensorHeight = properties.sensorHeight || 0;

        this.properties = properties;
        this.type = (properties.isNineSlice === undefined || properties.isNineSlice === true) ? Button.TYPE_NINE_SLICE : Button.TYPE_NORMAL;
        this.offsetX = properties.offsetX || 0;
        this.offsetY = properties.offsetY || 0;
        this._isTinted = false;
        this.background = new PIXI.Sprite();
        this.anchor.set(0.5, 0.5);
        this.label = new Label(options.style, text || 'Default Label Text');
        this.label.anchor.set(0.5, 0.5);
        this.label.rotation = properties.labelRotation || 0;

        if (this.type === Button.TYPE_NORMAL) {
            this.imageNormal = properties.imageNormal || null;
            this.imageSelected = properties.imageSelected || null;
            this.background = new PIXI.Sprite(this.imageNormal);
            this.background.centered();
            this.addChild(this.background);
        } else if (this.type === Button.TYPE_NINE_SLICE) {
            this.background = new NineSlice(properties.imageNormal, properties.padding || '10');
            this.background.setSize(properties.width || 200, properties.height || 80);
            this.addChild(this.background);
            this.background.tint = (properties.backgroundColorNormal);
            this.label.style.fill = properties.textColorNormal;
        }

        this.addChild(this.label);
        this.isHoverReactive = true;
        this.isHovered = false;

        this.eventMode = 'static';
        this.cursor = 'pointer';

//        this.on('pointerover', this.onHoverIn.bind(this));
//        this.on('pointerout', this.onHoverOut.bind(this));
        
//        this.on('pointerdown', this.onPointerDown.bind(this));
//        this.on('pointerup', this.onPointerUp.bind(this));
//        this.on('pointerupoutside', this.onPointerUpOutside.bind(this));
    }

    onUpdate(dt) {
        this.label.position.set(this.offsetX, this.offsetY);

//        if (this.isHoverReactive && !this.disabled && this.isTouchable) {
//            if (this._check(app.input.point)) {
//                this.onHoverIn();
//            } else {
//                this.onHoverOut();
//            }
//        }
//
//        if (!this.disabled) {
//            if (this.type === Button.TYPE_NORMAL) {
//                this.handleNormalType(dt);
//            } else if (this.type === Button.TYPE_NINE_SLICE) {
//                this.handleNineSlice(dt);
//            }
//        }
    }

    onHoverIn() {
        if (!this.isHovered) {
            if (this.type === Button.TYPE_NINE_SLICE) {
                this.background.tint = (this.properties.backgroundColorHover);
            }
            this.label.style.fill = this.properties.textColorHover;
            this.isHovered = true;
        }
    }

    onHoverOut() {
        if (this.isHovered) {
            if (this.type === Button.TYPE_NINE_SLICE) {
                this.background.tint = (this.properties.backgroundColorNormal);
            }
            this.label.style.fill = this.properties.textColorNormal;
            this.isHovered = false;
        }
    }

    handleNormalType(dt) {
        if (this.isMouseDown) {
            if (this.imageSelected) {
                if (this.imageNormal === this.imageSelected) {
                    this.background.tint = (this.properties.backgroundColorDown);
                } else if (this.imageNormal !== this.imageSelected) {
                    this.background.setTexture(this.imageSelected);
                }
            } else {
                this.background.tint = (this.properties.backgroundColorDown);
            }
        } else {
            this.background.tint = (this.properties.backgroundColorNormal);
            if (this.imageNormal && this.imageNormal !== this.imageSelected) {
                this.background.setTexture(this.imageNormal);
            }
        }
    }

    handleNineSlice(dt) {
        if (this.isMouseDown && !this._isTinted) {
            this.background.tint = (this.properties.backgroundColorDown);
            this.label.style.fill = this.properties.textColorDown;
            this._isTinted = true;
        } else if (!this.isMouseDown && this._isTinted) {
            this.background.tint = (this.properties.backgroundColorNormal);
            this.label.style.fill = this.properties.textColorNormal;
            this._isTinted = false;
        }
    }

    onMouseMove(event) {
        if (!this._check(event.point)) {
            this.eventIdx = -1;
            this.isMouseDown = false;
            this.onMouseCancel(event, this);
        }
    }

    get disabled() {
        return this._disabled;
    }

    set disabled(value) {
        if (value) {
            this.background.tint = (this.properties.backgroundColorDisabled);
            this.label.style.fill = this.properties.textColorDisabled;
            this.isTouchable = false;
        } else {
            this.background.tint = (this.properties.backgroundColorNormal);
            this.label.style.fill = this.properties.textColorNormal;
            this.isTouchable = true;
        }
        this._disabled = value;
    }

    get imageNormal() {
        return this._imageNormal;
    }

    set imageNormal(value) {
        this._imageNormal = value;
        if (this.type === Button.TYPE_NORMAL) {
            this.background.setTexture(value);
        }
    }

    get imageSelected() {
        return this._imageSelected;
    }

    set imageSelected(value) {
        this._imageSelected = value;
    }

    get txt() {
        return this._text;
    }

    set txt(value) {
        this._text = value;
        this.label.txt = value;
    }
}

Button.TYPE_NORMAL = 0;
Button.TYPE_NINE_SLICE = 1;
