export default class Actions {
    constructor() {
        throw new Error("Can't initialize Actions");
    }

    static actions = [];
    static isPaused = false;

    static add(action) {
        const index = this.actions.indexOf(action);
        if (index === -1) {
            this.actions.push(action);
            action.step(0); // it will execute immediately if duration is 0
        }
    }

    static remove(action) {
        this.actions.splice(this.actions.indexOf(action), 1);
    }

    static pause() {
        Actions.isPaused = true;
    }

    static resume() {
        Actions.isPaused = false;
    }

    static stopAll() {
        Actions.actions = [];
    }

    static stopByTag(tag, stopWithCallback) {
        for (let i = Actions.actions.length - 1; i >= 0; i--) {
            const action = Actions.actions[i];
            if (action && tag === action.tag) {
                action.stop(stopWithCallback); // this will remove the action from the array
            }
        }
    }

    static isRunning(tag) {
        for (let i = Actions.actions.length - 1; i >= 0; i--) {
            if (Actions.actions[i] && tag === Actions.actions[i].tag) {
                return true;
            }
        }
        return false;
    }

    static update(dt) {
        if (Actions.isPaused) {
            return;
        }

        const count = this.actions.length;

        for (let i = count - 1; i >= 0; i--) {
            if (this.actions[i] && !this.actions[i].isPaused) {
                this.actions[i].step(dt);
            }
        }
    }
}