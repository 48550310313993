import * as PIXI from 'pixi.js';
import MainScene from './mainScene.js';
import Actions from './tweening/actions.js';

const app = new PIXI.Application({
    width: 1920,
    height: 1080,
    backgroundColor: 0x14253e,
    view: document.querySelector('#scene'),
    resolution: 1,
    resizeTo: window
});

const scene = new MainScene(app.stage);

app.ticker.add((delta) => {
    Actions.update(app.ticker.deltaMS);
    scene.update(app.ticker.deltaMS);
});

window.addEventListener("resize", (event) => {
    scene.onResize();
});