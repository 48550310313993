import * as PIXI from 'pixi.js';
import { Assets, Sprite } from 'pixi.js';
import { Color } from 'pixi.js';

export default class NineSlice extends PIXI.Sprite {

    constructor(name, padding, width, height) {
        super(); // Call the super constructor with null texture
        this.initialize(name, padding, width, height);

    }

    initialize(name, padding, width, height) {
        this.backgroundParts = [];
        this.imageName = name;
        this.padding = padding;
        this._width = width;
        this._height = height;
        this.anchor.set(0.5, 0.5);
        this.setSize(width, height);

    }

    setSize(width, height) {
        this._width = width || 2;
        this._height = height || 2;
        if (this.backgroundParts) {
            this.buildBackground();
        }
    }

    async buildBackground() {
        if (!this.imageName) {
            return;
        }

        const width = this._width || 2;
        const height = this._height || 2;
        const pad = this.padding.toString().split(' ');

        const _top = Number(pad[0]) || 0;
        const _right = Number(pad[1]) || _top;
        const _bottom = Number(pad[2]) || _right;
        const _left = Number(pad[3]) || _bottom;

        const centerTexture = PIXI.utils.TextureCache[this.imageName];

        const center = PIXI.Sprite.from(centerTexture);
        if (_top + _bottom > center.height || _left + _right > center.width) {
            console.warn("Invalid Padding for Nine Slice");
            return;
        }

        for (const bp of this.backgroundParts) {
            bp.removeFromParent();
        }
        this.backgroundParts = [];

        center.texture = center.texture.clone();
        const f = center.texture.frame;
        center.texture.frame = new PIXI.Rectangle(f.x + _left, f.y + _top, f.width - _left - _right, f.height - _top - _bottom);
        center.position.set(_left - width / 2, _top - height / 2);
        center.stretch(width - _left - _right, height - _top - _bottom);
        this.addChild(center);
        this.backgroundParts.push(center);

        const top = new PIXI.Sprite(PIXI.utils.TextureCache[this.imageName]);
        top.texture = top.texture.clone();
        const frame = new PIXI.Rectangle(f.x + _left, f.y, Math.abs(f.width - _left - _right), _top);
        top.texture.frame = frame;
        top.stretch(width - _left - _right, _top);
        top.position.set(_left - width / 2, -height / 2);
        this.addChild(top);
        this.backgroundParts.push(top);

        const top_left = new PIXI.Sprite(PIXI.utils.TextureCache[this.imageName]);
        top_left.texture = top_left.texture.clone();
        top_left.texture.frame = new PIXI.Rectangle(f.x, f.y, _left, _top);
        top_left.position.set(-width / 2, -height / 2);
        this.addChild(top_left);
        this.backgroundParts.push(top_left);

        const top_right = new PIXI.Sprite(PIXI.utils.TextureCache[this.imageName]);
        top_right.texture = top_right.texture.clone();
        top_right.texture.frame = new PIXI.Rectangle(f.x + f.width - _right, f.y, _right, _top);
        top_right.position.set(width / 2 - _right, -height / 2);
        this.addChild(top_right);
        this.backgroundParts.push(top_right);

        const right = new PIXI.Sprite(PIXI.utils.TextureCache[this.imageName]);
        right.texture = right.texture.clone();
        right.texture.frame = new PIXI.Rectangle(f.x + f.width - _right, f.y + _top, _right, f.height - _top - _bottom);
        right.position.set(width / 2 - _right, -height / 2 + _top);
        right.stretch(_right, height - _top - _bottom);
        this.addChild(right);
        this.backgroundParts.push(right);

        const bottom_right = new PIXI.Sprite(PIXI.utils.TextureCache[this.imageName]);
        bottom_right.texture = bottom_right.texture.clone();
        bottom_right.texture.frame = new PIXI.Rectangle(f.x + f.width - _right, f.y + f.height - _bottom, _right, _bottom);
        bottom_right.position.set(width / 2 - _right, height / 2 - _bottom);
        this.addChild(bottom_right);
        this.backgroundParts.push(bottom_right);

        const bottom = new PIXI.Sprite(PIXI.utils.TextureCache[this.imageName]);
        bottom.texture = bottom.texture.clone();
        bottom.texture.frame = new PIXI.Rectangle(f.x + _right, f.y + f.height - _bottom, f.width - _left - _right, _bottom);
        bottom.position.set(-width / 2 + _left, height / 2 - _bottom);
        bottom.stretch(width - _left - _right, _bottom);
        this.addChild(bottom);
        this.backgroundParts.push(bottom);

        const bottom_left = new PIXI.Sprite(PIXI.utils.TextureCache[this.imageName]);
        bottom_left.texture = bottom_left.texture.clone();
        bottom_left.texture.frame = new PIXI.Rectangle(f.x, f.y + f.height - _bottom, _left, _bottom);
        bottom_left.position.set(-width / 2, height / 2 - _bottom);
        this.addChild(bottom_left);
        this.backgroundParts.push(bottom_left);

        const left = new PIXI.Sprite(PIXI.utils.TextureCache[this.imageName]);
        left.texture = left.texture.clone();
        left.texture.frame = new PIXI.Rectangle(f.x, f.y + _top, _left, f.height - _top - _bottom);
        left.position.set(-width / 2, -height / 2 + _top);
        left.stretch(_left, height - _top - _bottom);
        this.addChild(left);
        this.backgroundParts.push(left);

        for (const bp of this.backgroundParts) {
            bp.tint = this.tint;
        }
    }

    get tint() {
        return this._tint;
    }

    set tint(value) {
        this._tint = value;
        if (this.backgroundParts) {
            for (const bp of this.backgroundParts) {
                const hex = new Color(value).toHex();
                bp.tint = hex;
            }
        }
    }

    get width() {
        return this._width;
    }

    set width(value) {
        this._width = value;
        this.setSize(this._width, this._height);
    }

    get height() {
        return this._height;
    }

    set height(value) {
        this._height = value;
        this.setSize(this._width, this._height);
    }
}
