import Actions from './actions.js';

export default class Tween {
    constructor(object, to, bezier, duration, callback, context) {
        this.object = object;
        this.to = to;
        this.bezier = bezier;
        this.duration = duration;
        this.callback = callback || function () {};
        this._cycleCallback = function () {};
        this.context = context || this;

        this.timePassed = 0;
        this.ticks = 0;
        this.isFinished = false;
        this._delay = 0;
        this._name = '';
        this.tag = 0;

        this._repeat = 1; // it will run only once, 0 means it will run forever (if repeatable at all)
        this._cycles = 0; // the number of cycles done
        this.isPaused = false;
    }

    cycleCallback(callback, context) {
        this.context = context || this;
        this._cycleCallback = callback;
        return this;
    }

    repeat(repetitions) {
        this._repeat = repetitions;
        return this;
    }

    name(name) {
        this._name = name;
        return this;
    }

    delay(delay) {
        this._delay = delay || 100;
        return this;
    }

    start(tag) {
        return this.run(tag);
    }

    run(tag) {
        this.tag = tag;
        this.isFinished = false;

        if (this._delay) {
            setTimeout(() => {
                Actions.add(this);
            }, this._delay);
        } else {
            Actions.add(this);
        }

        return this;
    }

    stop(stopWithCallback) {
        Actions.remove(this);
        this.timePassed = 0;
        this.ticks = 0;
        this.isFinished = true;

        if (stopWithCallback) {
            this.invokeCallback();
        }

        return this;
    }

    pause() {
        this.isPaused = true;
        return this;
    }

    resume() {
        this.isPaused = false;
        return this;
    }

    invokeCallback() {
        this.onCallbackInvoked(this.object, this, this.tag);
        this.callback.call(this.context, this.object);
    }

    // sequencer methods

    onCallbackInvoked(object, tween, tag) {
        // This will be overwritten by the sequencer
    }

    applyValues() {
        // Overwrite this function to apply the values of the object
    }

    onEmitted() {
        // Overwrite this function
    }
}
