import * as PIXI from 'pixi.js';
import {Util} from './util.js';
import TweenBlink from './tweening/tween_blink';
import TweenGeneric from './tweening/tween_generic';
import WheelGraphics from './wheelGraphics';

export default class Wheel extends PIXI.Sprite {

    constructor(style) {
        //const texture = PIXI.utils.TextureCache[name];
        super();


        this.wheelGraphics = new WheelGraphics(style);
        this.addChild(this.wheelGraphics);

        this.delegate = null;

        this.direction = 1;
        this.anchor.set(0.5);

        this.isSpinning = false;

        this.values = [];
        this.segmentsCount = 0;

        this.isStopping = false;
        this.winIndex = 0;
        this.segmentAngle = 0;


        this.radians = 0; // current wheel radians.
        this.maxSpeed = 0.1; // max radians that can be added per loop iteration.
        this.currentSpeed = 0; // current radians that are added per loop iteration
        this.radiansUntilStop = 20.8; // wheel speed needs to be at zero when radians reaches this amount.
        this.stoppingDistance = Math.PI * 4;
        this.stoppingDistanceTraveled = 0;
        this.isSpinning = false; // is the wheel spinning or not.
        this.hasWin = false;

        this.currentSpeed = this.maxSpeed;
        this.lastSegmentPassIndex = 0;



    }

    init() {
        // needs to be called after values are assigned
        this.segmentsCount = this.values.length;
        this.stoppingDistance = Math.PI * 4;
        this.segmentAngle = 2 * Math.PI / this.segmentsCount;


    }

    spin() {
        this.isSpinning = true;
        this.isStopping = false;
        this.rotation = 0;
        this.radians = 0;
        this.stoppingDistanceTraveled = 0;
        this.currentSpeed = this.maxSpeed;

    }

    stopAtIndex(index) {
        this.isStopping = true;
        this.winIndex = index;
        index = (this.values.length - 1) - index;
        // the index will count backwards

        const segmentAngle = this.segmentAngle;
        const angleVariation = 0; //  Util.Math.randomFloat(-segmentAngle / 3, segmentAngle / 3);
        const stopAngle = index * segmentAngle + segmentAngle / 2 + angleVariation;
        const fullCircleAngle = Math.floor(this.rotation / (2 * Math.PI)) * (2 * Math.PI);

        let stopAt = fullCircleAngle + this.stoppingDistance + stopAngle;

        if (stopAt - this.rotation < this.stoppingDistance) {
            stopAt += (2 * Math.PI); // another full circle
        }

        this.radiansUntilStop = stopAt;
    }

    update(delta) {

        if (this.isSpinning === true) {
            if ((this.radians >= this.radiansUntilStop && this.isStopping)) {
                // stop spinning the wheel
                this.radians = this.radiansUntilStop;
                this.isSpinning = false;
                this.onWheelStopSpinning();
            }

            if (this.isStopping && this.radiansUntilStop - this.radians < this.stoppingDistance) {
                var brakes = 1 - this.stoppingDistanceTraveled / this.stoppingDistance;
                this.currentSpeed = brakes * this.maxSpeed;
                this.currentSpeed = Util.Math.clamp(this.currentSpeed, 0.001, 100);

                this.stoppingDistanceTraveled += this.currentSpeed;
            }

            // continue wheel spin
            this.radians += this.currentSpeed;
        }

        this.rotation = this.radians;

        // caluldate segment pass
        // lastSegmentPassIndex
        const currentSegmentPass = Math.floor(this.rotation / this.segmentAngle)

        if (currentSegmentPass !== this.lastSegmentPassIndex && this.isSpinning) {
            this.lastSegmentPassIndex = currentSegmentPass;
            this.onSegmentPass();
        }

    }

    onWheelStopSpinning() {
        this.hasWin = true;
        if (this.delegate && this.delegate.onWin) {
            this.delegate.onWin(this);
            this.higlightPie();
        }
    }

    onSegmentPass() {
        if (this.delegate && this.delegate.onWheelSegmentPass) {
            this.delegate.onWheelSegmentPass(this.currentSpeed, this);
        }
    }

    getWin() {
        return this.values[this.winIndex];
    }

    higlightPie() {

        if (this.wheelGraphics) {
            this.wheelGraphics.highlightPieAtIndex(this.winIndex);
            return;
        }

        const angle = this.segmentAngle;
        let radius = (this.id === 'bigWheel') ? 212 : 148;

        this.highlight = new PIXI.Graphics();
        this.highlight.lineStyle(radius, 0xffffff);
        this.highlight.arc(0, 0, radius, -angle / 2, angle / 2);
        this.addChild(this.highlight);
        this.highlight.blendMode = PIXI.BLEND_MODES.LUMINOSITY;
        this.highlight.rotation = (this.segmentAngle * 0.5) + this.segmentAngle * (this.winIndex - 2);
        this.highlight.alpha = 0.3;

        const tweenBlink = new TweenBlink(this.highlight, 0.7, null, 200).repeat(5);
        tweenBlink.run();
    }

}