import * as PIXI from 'pixi.js';

export default class Knob extends PIXI.Sprite {

    constructor() {
        const texture = PIXI.Texture.from(`assets/noko.png`);
        super(texture);
        this.anchor.set(0.5, 0);
        this.scale.set(0.5);


    }

    hit(currentSpeed) {
        this.rotation = -0.6;
    }

    update(delta) {

        const speed = 0.03;

        this.rotation += speed * delta / 16;
        
        if(this.rotation > 0){
            this.rotation = 0;
        }

    }

}