import * as PIXI from 'pixi.js';
import Button from '../ui/button';
import Label from '../ui/label';
import {Default} from '../ui/default';
import Styles from '../ui/styles';
import NineSlice from '../ui/nineSlice';
import {convertColor} from '../utils/helpers';
import { Assets } from 'pixi.js';

export default class Importer {
    constructor(screen) {
        this.initialize(screen);
    }

    initialize(screen) {
        this.inputs = [];
        this.screen = screen;
        this.content = null;
        this.resolveConstraints = true;
        this.objects = null;
    }

    dataToObject(data) {
        const object = this.unwrapObject(data, null);

        if (data.children && data.children.length) {
            this.importChildren(object, data.children);
        }

        return object;
    }

    findDataById(id, children) {
        children = children || this.objects;

        for (let i = 0; i < children.length; i++) {
            const c = children[i];
            if (c.id === id) {
                return c;
            }

            if (c.children) {
                const object = this.findDataById(id, c.children);
                if (object) {
                    return object;
                }
            }
        }

        return null;
    }

    findDataByType(type, children, result) {
        children = children || this.objects;
        result = result || [];
        for (let i = 0; i < children.length; i++) {
            const c = children[i];
            if (c.type === type) {
                result.push(c);
            }
            if (c.children) {
                this.findDataByType(type, c.children, result);
            }
        }

        return result.length ? result : null;
    }

    findDataByClassName(className, children, result) {
        children = children || this.objects;
        result = result || [];
        for (let i = 0; i < children.length; i++) {
            const c = children[i];
            if (c.className === className) {
                result.push(c);
            }

            if (c.children) {
                this.findDataByClassName(className, c.children, result);
            }
        }

        return result.length ? result : null;
    }

    findDataByTag(tag, children, result) {
        children = children || this.objects;
        result = result || [];
        for (let i = 0; i < children.length; i++) {
            const c = children[i];
            if (c.tag === tag) {
                result.push(c);
            }

            if (c.children) {
                this.findDataByTag(tag, c.children, result);
            }
        }

        return result.length ? result : null;
    }

    findDataByMethod(compareMethod, children, result) {
        children = children || this.objects;
        result = result || [];
        for (let i = 0; i < children.length; i++) {
            const c = children[i];
            if (compareMethod(c)) {
                result.push(c);
            }

            if (c.children) {
                this.findDataByMethod(compareMethod, c.children, result);
            }
        }

        return result.length ? result : null;
    }

    importObjects(objects, content) {
        this.content = content;
        this.objects = objects;

        for (let i = 0; i < objects.length; i++) {
            const data = objects[i];
            const object = this.unwrapObject(data, content);

            if (object) {
                content.addChild(object);
            }

            if (data.children && data.children.length) {
                this.importChildren(object, data.children);
            }
        }

        this.propagateImport(this.content.children);
    }

    propagateImport(children) {
        for (let i = children.length - 1; i >= 0; i--) {
            const c = children[i];
            if (c.onImport) {
                c.onImport();
            }

            if (c.onImportFinished) {
                c.onImportFinished();
            }
            this.propagateImport(c.children);
        }
    }

    importChildren(parent, children) {
        const unwrappedObjects = [];

        for (let i = 0; i < children.length; i++) {
            const data = children[i];
            const object = this.unwrapObject(data, parent);

            if (object) {
                parent.addChild(object);
                if (data.children && data.children.length) {
                    this.importChildren(object, data.children);
                }

                unwrappedObjects.push(object);
            }
        }
        return unwrappedObjects;
    }

    unwrapObject(data, parent) {
        let object = null;

        if (data.className) {
            if (window[data.className]) {
                object = new window[data.className]();
                object.mode = 'app';
            } else {
                console.warn('Class: "' + data.className + '" is not defined!');
                return new PIXI.Sprite();
            }

            if (object.setData) {
                object.setData(data, this.extract, this);
            }
        } else if (data.type === "ImageObject" && !this._customImport) {
            const texture = PIXI.utils.TextureCache[data.imageName];
//            console.log({texture: data.imageName});
            object = PIXI.Sprite.from(texture);
        } else if (data.type === "LabelObject") {
            const style = this.createStyle(data);
            object = new Label(style);
            object.txt = data.txt;
        } else if (data.type === "ButtonObject") {
            const props = this.createProperties(data);
            const style = this.createStyle(data);

            const type = props.isNineSlice ? Button.TYPE_NINE_SLICE : Button.TYPE_NORMAL;
            object = new Button(data.txt, {properties: props, style: style, type: type});
        } else if (data.type === "ContainerObject") {
            object = new PIXI.Sprite();
            object.enableSensor();
        } else if (data.type === "NineSliceObject") {
            const props = this.createProperties(data);

            object = new NineSlice(props.backgroundName, props.padding.toString(), props.width, props.height);
            object.tint = props.tintColor;
        }

        if (!object) {
            object = new PIXI.Sprite();
        }

        if (data.position) {
            object.position.set(data.position.x, data.position.y);
        }

        if (object.anchor) {
            if (data.anchor) {
                object.anchor.set(data.anchor.x, data.anchor.y);
            } else {
                object.anchor.set(0.5, 0.5);
            }
        }

        if (data.scale) {
            object.scale.set(data.scale.x, data.scale.y);
        }

        if (data.tag) {
            object.tag = data.tag;
        }

        if (data.rotation) {
            object.rotation = data.rotation;
        }

        if (data.alpha !== undefined) {
            object.alpha = data.alpha;
        }

        object.visible = (data.visible === undefined) ? true : data.visible;

        if (data.id !== undefined) {
            object.id = data.id;
            object.id = object.id.toLowerCase();
        } else {
            object.id = PIXI.utils.uid();
        }

        if (data.type !== "NineSliceObject") {
            object.tint = data.tint || 0xffffff;
        }

        if (data.properties && data.properties._custom) {
            object._properties = data.properties._custom;
        }

        return object;
    }

    extract(key, data) {
        if (data.properties && data.properties._custom) {
            for (let i = 0; i < data.properties._custom.length; i++) {
                const d = data.properties._custom[i];
                if (d.key === key) {
                    return d.value;
                }
            }
        }

        return null;
    }

    applyValues(object, values) {
        for (const prop in values) {
            if (Object.prototype.hasOwnProperty.call(values, prop)) {
                const defaultValue = values[prop];
                object[prop] = defaultValue;
            }
        }
    }

    createStyle(data) {
        const style = {};
        this.applyValues(style, Default.stylesClean[data.type]);

        if (data.styleName) {
            const styledProperties = Styles.types[data.type][data.styleName];
            if (styledProperties) {
                this.applyValues(style, styledProperties.style);
            } else {
                console.warn("Style: " + data.styleName + " not found");
            }
        }

        this.applyValues(style, data.style || {});
        return style;
    }

    createProperties(data) {
        const properties = {};
        this.applyValues(properties, Default.properties[data.type]);

        if (data.styleName) {
            const styledProperties = Styles.types[data.type][data.styleName];
            if (styledProperties) {
                this.applyValues(properties, styledProperties.properties);
            } else {
                console.warn("Style: " + data.styleName + " not found");
            }
        }

        this.applyValues(properties, data.properties);
        return properties;
    }
}
