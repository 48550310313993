import * as PIXI from 'pixi.js';
import TweenGeneric from './tweening/tween_generic.js';
import Bezier from './tweening/bezier.js';
import TweenBlink from './tweening/tween_blink';

export default class Slot extends PIXI.Container {

    constructor() {

        super();

        this.itemImages = [
            'aviatrix',
            'gonzosquest',
            'jamminjars',
            'mental',
            'reactoonz',
            'wanted'
        ];

        this.config = [
            {
                name: 'Aviatrix',
                url: '',
            },
            {
                name: 'Gonzo\'s Quest',
                url: '',
            },
            {
                name: 'Jammin Jars',
                url: '',
            },
            {
                name: 'Mental',
                url: '',
            }
            , {
                name: 'Reactoonz',
                url: '',
            }
            , {
                name: 'Wanted',
                url: '',
            }
        ];

        this.items = [];
        this.stopIndex = 0;

        this.rollY = 0;
        this.speed = 1;
        this.itemHeight = 214;
        this.decelerationRate = 0;
        this.tweenStopTime = 6000;

        this.maskRadius = 100 * 2;

        this.isSpinning = false;
        this.isStopping = false;
        this.hasWin = false;

        for (var i = 0; i < this.itemImages.length; i++) {
            const imgName = this.itemImages[i];
            this.createItem(imgName);
        }

        this.createItem(this.itemImages[0]);

        this.rollHeight = this.itemHeight * (this.items.length - 1);

        this.drawAtRoll();

        const graphics = new PIXI.Graphics();
        graphics.lineStyle(0); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        graphics.beginFill(0xffffff, 1);
        graphics.drawCircle(0, 0, this.maskRadius);
        graphics.endFill();
        graphics.scale.set(0.5);

        this.addChild(graphics);
        this.mask = graphics;
    }

    createItem(imgName) {
        const texture = PIXI.Texture.from(`assets/icons/${imgName}.png`);
        const sprite = PIXI.Sprite.from(texture);
        sprite.x = -this.itemHeight / 2;
        sprite.scale.set(0.5);
        this.addChild(sprite);
        this.items.push(sprite);
    }

    spin() {
        this.isSpinning = true;


    }

    getWin() {
        return this.config[this.stopIndex];
    }

    stopAtIndex(index) {
        // stop at index
        this.stopIndex = index;
        const stopAtY = index * this.itemHeight;

        const startOfThisRoll = Math.floor(this.rollY / this.rollHeight) * this.rollHeight;

        this.finalStopAt = startOfThisRoll + stopAtY + this.rollHeight * 2;
        this.isStopping = true;

        const bezier = new Bezier(.16, .55, .96, 1.05);
        const tween = new TweenGeneric(this, {rollY: this.finalStopAt}, bezier, this.tweenStopTime, () => {
            this.hasWin = true;
            this.onWin();
        });
        tween.run();
    }

    onWin() {
        if (this.delegate && this.delegate.onWin) {
            this.delegate.onWin(this);

        }

        let radius = 100;

//        this.highlight = new PIXI.Graphics();
//        this.highlight.beginFill(0xffffff);
//        this.highlight.drawCircle(0, 0, radius);
//
//        this.highlight.blendMode = PIXI.BLEND_MODES.LUMINOSITY;
//        this.highlight.alpha = 0.3;
//        this.addChild(this.highlight);

//        const tweenBlink = new TweenBlink(this.highlight, 0.7, null, 200).repeat(5);
//        tweenBlink.run();
    }

    drawAtRoll() {
        const relativeRoll = this.rollY % this.rollHeight; // - this.rollHeight;
        const height = this.itemHeight;
        for (var i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            const y = relativeRoll - i * height; // - this.itemHeight / 2;
            const wholePart = Math.floor(y / this.itemHeight) * this.itemHeight;
            const rest = y - wholePart;
            item.y = wholePart - this.itemHeight / 2 + rest;
        }
    }

    update(delta) {
        if (this.isSpinning) {

            if (this.isStopping) {
                // Tweening library takes off 
            } else {
                this.rollY += this.speed * delta;
            }

            this.drawAtRoll();
        }



    }

}