import * as PIXI from 'pixi.js';
import {Vector} from './utils/helpers';
import {Util} from './util.js';

export default class WheelGraphics extends PIXI.Container {

    constructor(style) {
        super();

        this.style = style;

        this.pices = [];

        const fractionAngle = 360 / 8;

        this.overlay = new PIXI.Graphics();


        // Draw Lines
        const vector = new Vector(0, 0);
        vector.setLength(style.radius);

        this.overlay.lineStyle(style.linesThickness, style.linesColor, 1);
        for (var i = 0; i < 8; i++) {
            const angle = fractionAngle * i;
            const radians = Util.Math.degreesToRadians(angle);
            vector.setAngle(radians);
            this.overlay.moveTo(vector.x, vector.y);
            this.overlay.lineTo(0, 0);
        }

        this.overlay.lineStyle(style.shadowThickness, 0x000000, 0.2); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        this.overlay.beginFill(0xffffff, 0);
        this.overlay.drawCircle(0, 0, style.radius - style.shadowThickness / 2 - style.outerWhiteLineThickness);
        this.overlay.endFill();

        this.overlay.lineStyle(style.outerWhiteLineThickness, 0xffffff, 1); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        this.overlay.beginFill(0xffffff, 0);
        this.overlay.drawCircle(0, 0, style.radius);
        this.overlay.endFill();

        // add pices
        for (var i = 0; i < 8; i++) {
            const angle = fractionAngle * i;
            const radians = Util.Math.degreesToRadians(angle);

            const texture = PIXI.Texture.from(`assets/pie-piece.png`);
            const piece = new PIXI.Sprite(texture);
            piece.tint = i % 2 === 0 ? style.color : style.evenColor;
            piece.anchor.set(0, 1);
            piece.rotation = radians;
            piece.scale.set(this.style.pieScale);
            this.addChild(piece);

            // add Text 

            const text = new PIXI.Text(style.labels[i], new PIXI.TextStyle({fontFamily: 'Montserrat-Bold', fontSize: this.style.fontSize, fill: '#ffffff'}));
            this.addChild(text);
            text.anchor.set(0.5, 0.5);

            const textAngle = Util.Math.degreesToRadians(angle) - Util.Math.degreesToRadians(fractionAngle) - Util.Math.degreesToRadians(fractionAngle) / 2;
            vector.setLength(this.style.radius - this.style.textOffsetY);
            vector.setAngle(textAngle);
            text.rotation = Util.Math.degreesToRadians(angle) + Util.Math.degreesToRadians(fractionAngle) / 2;
            text.position.set(vector.x, vector.y);

            this.pices.push(piece);


        }
        //  

        this.addChild(this.overlay);

    }

    highlightPieAtIndex(index) {
        this.pices[index].tint = this.style.winColor;
    }

}
