import Tween from './tween';
import Bezier from './bezier';

export default class TweenBlink extends Tween {
    constructor(object, to, bezier, duration, callback, context) {
        super(object, to, bezier, duration, callback);
        bezier = bezier ? bezier : new Bezier(0.26, 0.66, 0.32, 1.01);
        this.object = object;
        this.context = context;
        this._originalAlpha = object.alpha;
        this.startAlpha = object.alpha;
        this.difference = to - this.startAlpha;
        this._repeat = 0;
        this.timePassed = 0;
        this._cycles = 0;
    }

    step(dt) {
        this.timePassed += dt;
        let s = this.timePassed / this.duration;
        s = s >= 1 ? 1.0 : s;
        let newAlpha = 0;

        if (s < 0.5) {
            let percent = s / 0.5;
            let bstep = this.bezier ? this.bezier.get(percent) : percent;
            newAlpha = this.startAlpha + (this.to - this._originalAlpha) * bstep;
        } else {
            let percent = (s - 0.5) / 0.5;
            let bstep = this.bezier ? this.bezier.get(percent) : percent;
            newAlpha = this.to - (this.to - this._originalAlpha) * bstep;
        }

        this.object.alpha = newAlpha;

        if (s === 1) {
            this._cycles++;
            this._cycleCallback.call(this.context, this._cycles);

            if (this._cycles === this._repeat) {
                this.invokeCallback();
                this.stop();
            }
            this.timePassed -= this.duration;
        }
    }
}
