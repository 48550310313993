const Styles = {
    colors: {},
    types: {
        Button: {},
        Label: {},
        Input: {} // For future use
    }
};

Styles.types.ButtonObject = Styles.types.Button;
Styles.types.LabelObject = Styles.types.Label;
Styles.types.InputObject = Styles.types.Input;

Styles.addLabel = function (name, style, properties) {
    const s = {...style};
    const p = properties ? {...properties} : null;
    Styles.types.Label[name] = {
        style: s,
        properties: p
    };
};

Styles.addButton = function (name, style, properties) {
    const s = {...style};
    const p = {...properties};
    Styles.types.Button[name] = {
        style: s,
        properties: p
    };
};

Styles.addColor = function (name, color) {
    Styles.colors[name] = color;
};

export default Styles;
