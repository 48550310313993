export default class Bezier {

    constructor(mX1, mY1, mX2, mY2) {
        this.initialize(mX1, mY1, mX2, mY2);
    }

    initialize(mX1, mY1, mX2, mY2) {
        this.mX1 = mX1;
        this.mY1 = mY1;
        this.mX2 = mX2;
        this.mY2 = mY2;
    }

    A(aA1, aA2) {
        return 1.0 - 3.0 * aA2 + 3.0 * aA1;
    }

    B(aA1, aA2) {
        return 3.0 * aA2 - 6.0 * aA1;
    }

    C(aA1) {
        return 3.0 * aA1;
    }

    GetSlope(aT, aA1, aA2) {
        return 3.0 * this.A(aA1, aA2) * aT * aT + 2.0 * this.B(aA1, aA2) * aT + this.C(aA1);
    }

    CalcBezier(aT, aA1, aA2) {
        return ((this.A(aA1, aA2) * aT + this.B(aA1, aA2)) * aT + this.C(aA1)) * aT;
    }

    get(aX) {
        if (this.mX1 === this.mY1 && this.mX2 === this.mY2)
            return aX; // linear
        return this.CalcBezier(this.GetTForX(aX), this.mY1, this.mY2);
    }

    GetTForX(aX) {
        // Newton raphson iteration
        let aGuessT = aX;
        for (let i = 0; i < 4; ++i) {
            const currentSlope = this.GetSlope(aGuessT, this.mX1, this.mX2);
            if (currentSlope === 0.0)
                return aGuessT;
            const currentX = this.CalcBezier(aGuessT, this.mX1, this.mX2) - aX;
            aGuessT -= currentX / currentSlope;
        }
        return aGuessT;
    }
}
